const INITIAL_STATE = {
  isAuth: false,
  isLoading: true,
  errorCreatePatient: false,
  signOut: false,
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_USER':
      return {
        ...state,
        ...action.user,
        isLoading: false,
      };
    case 'SET_LOADING':
      return {
        ...state,
        isLoading: true
      };
    case 'UNSET_LOADING':
      return {
        ...state,
        isLoading: false
      };
    case 'SET_ERROR_CREATE_PATIENT':
      return {
        ...state,
        isLoading: false,
        errorCreatePatient: action.newPatient,
        err: action.err
      };
    case 'UNSET_ERROR_CREATE_PATIENT':
      return {
        ...state,
        errorCreatePatient: false
      };
    case 'UNSET_ERROR_CREATE_THERAPIST':
      return {
        ...state,
        errorCreateTherapist: false
      };
    case 'SET_ERROR_CREATE_THERAPIST':
      return {
        ...state,
        errorCreateTherapist: action.newTherapist,
        err: action.err
      }
    case 'CLEAN_SESSION_USER':
      return INITIAL_STATE
    default:
      return state
  }
}
