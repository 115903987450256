import React from "react";
import {Badge} from "reactstrap";

const BadgeTherapist = ({user, viewListPatients}) => {

  if (user.manage.length > 0 ) {
    return (
      <Badge style={{marginRight: "15px", cursor: "pointer"}}
             onClick={(e) => viewListPatients(e, user)} color={"info"}>
        <i style={{color: "white", fontSize: "15px"}} className="fas fa-search"/>
        <span style={{verticalAlign: "text-top"}}>Liste des patients</span>
      </Badge>
    )
  }
  return (
    <Badge style={{marginRight: "15px", cursor: "pointer"}}
           onClick={(e) => viewListPatients(e, user)} color={"primary"}>
      <span style={{verticalAlign: "text-top"}}>Aucun patient</span>
    </Badge>
  )
}

export default BadgeTherapist

