/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useState, useEffect} from "react";
import {Redirect} from "react-router-dom";
import {
  Badge,
  Card,
  CardHeader,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Button,
  Table,
  Container,
  Row,
  Alert,
} from "reactstrap";
import styled from '@emotion/styled'
import useAuth from "../../hooks/useAuth";
import {ModalCreatePatient} from "../../components/Modal";
import {HeaderPrimaryBtn} from "../../components/Headers/Header";
import BadgeAbonnement from "../../components/BadgeAbonnement";

import onSearch from "../../utils/search";

const Tr = styled.tr`
  transition: 0.2s;
  &:hover {
    background-color: #c8860914;
    transition: 0.2s;
  }
`

const BlockButton = styled.div`
  margin-top: 60px;
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
`

const ListPatients = ({history}) => {
  const {user, allPatients, createNewPatient, CleanErrorPatient} = useAuth();
  const [query, setQuery] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [createForm, toggleForm] = useState(false);
  const [isSuccess, toggleSuccess] = useState(false)

  useEffect(() => {
    if (user.errorCreatePatient)
      toggleForm(user.errorCreatePatient)
  }, [user.errorCreatePatient])

  useEffect(() => {
    return () => {
      if (user.errorCreatePatient)
        CleanErrorPatient()
    }
  })

  const handleOnChange = (e) => {
    e.preventDefault();
    setQuery(e.target.value);
    if (query.length > 1) {
      const res = onSearch(query, allPatients, ["first_name", "last_name", "email", "phone"], allPatients);
      setSearchResult(res)
    }
    else {
      setSearchResult([])
    }
  };

  const handleModalCreatePatient = (e) => {
    console.log(createForm)
    e.preventDefault();
    toggleForm(!createForm)
  }

  const createPatient = (newPatient, e) => {
    e.preventDefault();
    createNewPatient(newPatient, user)
  }

  const handleOnSubmit = (e) => {
    e.preventDefault()
    if (query.length > 1) {
      const res = onSearch(query, allPatients, ["first_name", "last_name", "email", "phone"], allPatients);
      setSearchResult(res)
    }
  }

  return user.isAdmin || user.roles[0].name === "speechTherapist" ? (

    <>
      {isSuccess &&
      <Alert style={{
        position: "fixed",
        zIndex: "10",
        top: "11px",
        right: '10px',
        padding: '10px',
      }} color="success">
        <strong>Succès!</strong> Vous avez ajouté un nouveau patient
      </Alert>}
      {createForm && (
        <ModalCreatePatient closeModal={handleModalCreatePatient} toggle={createForm} callback={createPatient}/>
      )}
      {/*<ModalDelete
        toggle={isModal}
      />*/}
      <HeaderPrimaryBtn>
        {allPatients.length > 0 && false &&  <Button onClick={(e) => handleModalCreatePatient(e)} style={{marginBottom: "15px", cursor: "pointer"}} className="btn-icon btn-3" color="secondary" type="button">
          <span className="btn-inner--icon">
            <i className="ni ni-single-02" />
          </span>
          <i style={{position: "fixed", left: "25px"}} className="ni ni-fat-add" />
          <span style={{marginLeft: "15px"}} className="btn-inner--text">Ajouter un nouveau patient</span>
        </Button>}
      </HeaderPrimaryBtn>
      {/* Page content */}
      <Container style={{marginTop: "-11vh", paddingBottom: "50px"}} fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader style={{display: "flex", alignItems: "center"}} className="border-0">
                <h3 className="mb-0">Liste de mes patients</h3>
                <Form onSubmit={handleOnSubmit}  onClick={(e) => e.preventDefault()}
                      className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto">
                  <FormGroup className="mb-0">
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i style={{color: "black"}} className="fas fa-search"/>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input onChange={handleOnChange} style={{color: "black"}} placeholder="Search" type="text"/>
                    </InputGroup>
                  </FormGroup>
                </Form>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                <tr>
                  <th scope="col">Utilisateur</th>
                  <th scope="col">Adresse Email</th>
                  <th scope="col">Téléphone</th>
                  <th scope="col">Abonnement</th>
                  {/*<th scope="col">Actions</th>*/}

                </tr>
                </thead>
                <tbody>
                {searchResult.length > 0 &&
                searchResult.map((user, id) => {
                  return (
                    <Tr key={id}>
                      <td style={{
                        display: 'flex',
                        alignItems: "center",
                        justifyContent: "flex-start",
                        height: "48px"
                      }}>
                        <Badge style={{background: "#172b4e", marginRight: "15px", cursor: "pointer", display: "flex", alignItems: "center", justifyContent: "center"}}
                               onClick={(e) => history.push("/dashboard/index?user=" + user.id)} color={"#162B4D"}>
                          <i style={{color: "white", fontSize: "15px"}} className="ni ni-tv-2"/>
                          <span style={{color: "white"}}>VOIR</span>
                        </Badge>
                        {user.first_name} {user.last_name}
                      </td>
                      <td>{user.email}</td>
                      <td>{user.phone}</td>
                      <td>
                        <Badge color="" className="badge-dot">
                          {user.roles[0].name === "patient" && <BadgeAbonnement user={user}/>}
                        </Badge>
                      </td>
                      {/*<td>
                        <ButtonDelete onClick={(e) => toggleDeleteConfirmation(e, user)}>
                          <i style={{
                            color: "#D8636F",
                            fontSize: "25px",
                            verticalAlign: "middle"
                          }}
                             className="ni ni-fat-remove"/>
                        </ButtonDelete>

                      </td>*/}
                    </Tr>
                  )
                })
                }

                {console.log('Nb patients : ',allPatients.length)}
                {searchResult.length < 1 &&
                allPatients.map((user, id) => {
                  return (
                      <Tr key={id} >
                        <td style={{
                          display: 'flex',
                          alignItems: "center",
                          justifyContent: "flex-start",
                          height: "48px"
                        }}>
                          <Badge style={{background: "#172b4e", marginRight: "15px", cursor: "pointer", display: "flex", alignItems: "center", justifyContent: "center"}}
                                 onClick={(e) => history.push("/dashboard/index?user=" + user.id)} color={"#162B4D"}>
                            <i style={{color: "white", fontSize: "15px"}} className="ni ni-tv-2"/>
                            <span style={{color: "white"}}>VOIR</span>
                          </Badge>
                          {user.first_name} {user.last_name}
                        </td>
                        <td>{user.email}</td>
                        <td>{user.phone}</td>
                        <td>
                          <Badge color="" className="badge-dot">
                            {user.roles[0].name === "patient" && <BadgeAbonnement user={user}/>}
                          </Badge>
                        </td>
                        {/*<td>
                          <ButtonDelete onClick={(e) => toggleDeleteConfirmation(e, user)}>
                            <i style={{
                              color: "#D8636F",
                              fontSize: "25px",
                              verticalAlign: "middle"
                            }}
                               className="ni ni-fat-remove"/>
                          </ButtonDelete>

                        </td>*/}
                      </Tr>
                  )
                })
                }
                </tbody>
              </Table>
            </Card>
            {allPatients.length < 1 &&
            <BlockButton>
              <h4>Vous n'avez pas encore de patient.</h4>

              {false && <Button onClick={(e) => handleModalCreatePatient(e)} className={"mt-4"} color="default" type="button">
                <span className="btn-inner--icon">
                  <i className="ni ni-single-02"/>
                </span>
                <i style={{position: "fixed", left: "25px"}} className="ni ni-fat-add"/>
                <span style={{marginLeft: "15px"}} className="btn-inner--text">Ajouter un nouveau patient</span>
              </Button>}
            </BlockButton>}
          </div>
        </Row>
      </Container>
    </>
  ) : <Redirect from={"/dashboard/users"} to="/dashboard/index"/>;
};

export default ListPatients;
