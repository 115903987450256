/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useState} from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col
} from "reactstrap";

import {HeaderDefault} from "../../components/Headers/Header";
import useAuth from "../../hooks/useAuth";


// core components

const Profile = () => {
  const {user, updateUser} = useAuth();
  const [currentUser, setCurrentUser] = useState(user)

  /*useEffect(() => {
    if (user)
      setCurrentUser(user)
  }, [user])*/

  const handleChange = (e) => {
    e.preventDefault();
    setCurrentUser({
      ...currentUser,
      [e.target.name]: e.target.value
    })
  };

  const handleSubmit = (e) => {
    e.preventDefault()
    updateUser({
      last_name: currentUser.last_name,
      first_name: currentUser.first_name,
      email: currentUser.email,
      phone: currentUser.phone,
      address: currentUser.address,
      city: currentUser.city,
      zip_code: currentUser.zip_code,
    }, currentUser.id)
  }

  return (
    <>
      <HeaderDefault/>
      {/* Page contt */}
      <Container style={{marginTop: "-15vh"}} fluid>
        <Row style={{maxWidth: "800px"}}>
          <Col>
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Mes informations personelles</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Nom
                          </label>
                          <Input
                            onChange={handleChange}
                            name="last_name"
                            defaultValue={currentUser.last_name}
                            className="form-control-alternative"
                            id="input-username"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            Prénom
                          </label>
                          <Input
                            onChange={handleChange}
                            name="first_name"
                            defaultValue={currentUser.first_name}
                            className="form-control-alternative"
                            id="input-first-name"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Adresse email
                          </label>
                          <Input
                            onChange={handleChange}
                            name="email"
                            defaultValue={currentUser.email}
                            className="form-control-alternative"
                            id="input-email"
                            type="email"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-last-name"
                          >
                            Téléphone
                          </label>
                          <Input
                            onChange={handleChange}
                            name="phone"
                            defaultValue={currentUser.phone}
                            className="form-control-alternative"
                            id="input-last-name"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <hr className="my-4"/>
                  <div className="pl-lg-4">
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-address"
                          >
                            Adresse
                          </label>
                          <Input
                            onChange={handleChange}
                            name="address"
                            defaultValue={currentUser.address}
                            className="form-control-alternative"
                            id="input-address"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-city"
                          >
                            Ville
                          </label>
                          <Input
                            onChange={handleChange}
                            name="city"
                            defaultValue={currentUser.city}
                            className="form-control-alternative"
                            id="input-city"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-country"
                          >
                            Code postal
                          </label>
                          <Input
                            onChange={handleChange}
                            name="zip_code"
                            defaultValue={currentUser.zip_code}
                            className="form-control-alternative"
                            id="input-postal-code"
                            type="number"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                </Form>
                <Button onClick={handleSubmit} style={{float: "right"}} className="btn-icon btn-3" color="default" type="button">
                  <span className="btn-inner--icon">
                    <i className="ni ni-active-40" />
                  </span>
                  <span className="btn-inner--text">Sauvegarder</span>
                </Button>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Profile;
