export const setAllPatients = allPatients => ({
  type: 'SET_PATIENT_LIST',
  allPatients,
});

export const addPatientsList = newPatient => ({
  type: 'ADD_PATIENT_LIST',
  newPatient
})

export const cleanSessionTherapist = () => ({
  type: 'CLEAN_THERAPIST',
})


