import React, { useEffect, useState } from 'react';

import useAuth from '../hooks/useAuth';

const Tablet = ({ location, history }) => {

  const { user, allUsers } = useAuth();
  const [userSource, setUserSource] = useState(false);

  const production = true;

  const rootUrl = production ? 'https://dp965k3o4g6iu.cloudfront.net' : 'https://d3dr5zrvvfiuxi.cloudfront.net';
  //const rootUrl = 'http://localhost:19006';
  const displayCurrentUserAccount = user.id === userSource.id;

  const fullUrl = `${rootUrl}/#////auth/login/?user=${userSource.id}&accessToken=${userSource.accessToken}&idToken=${userSource.idToken}&displayCurrentUserAccount=${displayCurrentUserAccount}`;

  useEffect(() => {

    let searchParams = new URLSearchParams(location.search);
    searchParams = searchParams.get('user');
    if (!userSource && user.id) {
      if (searchParams) {
        let isValidUser = false;
        if (user.roles[0].name === 'speechTherapist') {
          isValidUser = user.manage.find(e => e.id === searchParams);
        } else if (user.isAdmin) isValidUser = allUsers.find(e => e.id === searchParams);
        console.log("User data : ", user);
        if (isValidUser)
          setUserSource({
            id: isValidUser.id,
            idToken: user.idToken,
            accessToken: user.accessToken,
          });
        else history.push('/dashboard/index');
      } else setUserSource(user);
    } else if (user.id) setUserSource(user);
  }, [location]);

  return (
      <div>
        {
          userSource.id &&
          <div
            style={{ height: '100vh', width: '100%' }}
            dangerouslySetInnerHTML={{ __html: `<iframe src=${fullUrl} style='width: 100%;height: 100%; background: white;' frameBorder='0' />` }}
          />
        }
      </div>
    );
};

export default Tablet;
