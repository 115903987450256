import React, {useEffect, useState} from "react"
import {
  Modal,
  Card,
  Button,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  CardHeader, Row, Col
} from "reactstrap";

import useAuth from "../hooks/useAuth";

export const ModalDelete = ({toggle}) => {
  const [isOpen, setOpen] = useState(false);

  console.log("TOTTTGLE", toggle)

  useEffect(() => {
    if (toggle)
      setOpen(true)
  }, [toggle]);

  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={isOpen}
    >
      <div className="modal-header">
        <h5 className="modal-title" id="modal-title-notification">
          Confirmation de suppression
        </h5>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => setOpen(false)}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        <div className="py-3 text-center">
          <i className="ni ni-bell-55 ni-3x"/>
          <h4 className="heading mt-4">Attention !</h4>
          <p>
            Vous êtes sur le point de supprimer le compte suivant: <br/><br/>
            [ {toggle.roles[0].name} ]<br/> {toggle.first_name} {toggle.last_name}<br/>
            {toggle.email}
          </p>
        </div>
      </div>
      <div className="modal-footer">
        <Button className="btn-white" color="default" type="button">
          Je veux supprimer
        </Button>
        <Button
          className="text-white ml-auto"
          color="link"
          data-dismiss="modal"
          type="button"
          onClick={() => setOpen(false)}
        >
          Annuler
        </Button>
      </div>
    </Modal>
  )
}

export const ModalCreatePatient = ({closeModal, toggle, callback}) => {
  const [isOpen, setOpen] = useState(true);
  const [newPatient, setNewPatient] = useState({
    password: "",
    rePassword: "",
    email: "",
    last_name: "",
    phone: ""
  });
  const [passwordVisible, togglePasswordVisible] = useState(false)
  const [isError, setError] = useState({
    password: false,
    email: false,
    empty: false,
  })
  const [isSuccess, setSuccess] = useState(false)

  useEffect(() => {
    if (toggle) {
      if (toggle.email) {
        setNewPatient(toggle)
        setError({...isError, email: toggle.code || true})
      } else (setOpen(true))
    }

  }, [toggle]);

  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        setOpen(false)
      }, 2000)
    }
  }, [isSuccess])

  const handleFormChange = (e) => {
    e.preventDefault();

    if(e.target.name == 'email'){
      setNewPatient({
        ...newPatient,
        [e.target.name]: e.target.value.toLowerCase()

      })
    } else {
      setNewPatient({
        ...newPatient,
        [e.target.name]: e.target.value
      })
    }
    if (isError.empty || isError.email)
      setError({password: false, empty: false, email: false})
  };

  const handleCreatePatient = (e) => {
    const isEmpty = !Object.values(newPatient).some(x => (x !== ''));
    if (isEmpty) setError({...isError, empty: true})
    else if (newPatient.password) {
      if (newPatient.password !== newPatient.rePassword) {
        setError({...isError, password: "notSame"})
      }
      else if (!newPatient.password.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z=!#.]{8,}$/))
        setError({...isError, password: "validation"})
      else {
        setError({password: false, empty: false})
        callback(newPatient, e)
      }
    }
  }

  return (
    <Modal
      className="modal-dialog-centered"
      size="sm"
      isOpen={isOpen}
    >
      <div className="modal-body p-0">
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent">
            <div style={{display: "flex", justifyContent: "space-between"}}>
              <h3 className="mb-0">Création d'un nouveau compte</h3>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={closeModal}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div style={{marginTop: "10px"}} className="text-muted">
              <small>Le mot de passe doit être composé au minimum de :<br/> 8 caractères, une majuscule, une lettre et
                un chiffre</small>
            </div>
          </CardHeader>
          <CardBody>

            <Form>
              <div>
                <Row>
                  <Col lg="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-username"
                      >
                        Nom
                      </label>
                      <Input
                        className="input-group-alternative"
                        required
                        onChange={handleFormChange}
                        name="last_name"
                        id="input-username"
                        type="text"
                        defaultValue={newPatient.last_name}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-first-name"
                      >
                        Téléphone
                      </label>
                      <Input
                        className="input-group-alternative"
                        required
                        onChange={handleFormChange}
                        name="phone"
                        id="input-first-name"
                        type="text"
                        defaultValue={newPatient.phone}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg="12">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-email"
                      >
                        Adresse email
                      </label>
                      <Input
                        style={isError.email ? {border: "1px solid #D8636F"} : null}
                        className="input-group-alternative"
                        required
                        onChange={handleFormChange}
                        name="email"
                        id="input-email"
                        type="email"
                        defaultValue={newPatient.email.toLowerCase()}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-email"
                      >
                        Mot de passe
                      </label>
                      <InputGroup style={isError.password ? {border: "1px solid #D8636F"} : null}
                                  className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open"/>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input onChange={handleFormChange}
                               name="password"
                               placeholder="Mot de passe"
                               type={passwordVisible ? "text" : "password"}
                               required
                               defaultValue={newPatient.password}
                               autoComplete="new-password"/>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-email"
                      >
                        Confirmation
                      </label>
                      <InputGroup style={isError.password ? {border: "1px solid #D8636F"} : null}
                                  className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open"/>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input onChange={handleFormChange}
                               name="rePassword"
                               placeholder="Mot de passe"
                               type={passwordVisible ? "text" : "password"}
                               required
                               defaultValue={newPatient.rePassword}
                               autoComplete="new-password"/>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <button
                    style={{border: "none", background: "none", fontSize: "0.9rem", margin: "auto", marginTop: "-10px", cursor: "pointer"}}
                    onClick={(e) => {
                      e.preventDefault()
                      togglePasswordVisible(!passwordVisible)
                  }}>
                    {passwordVisible ? "Masquer" : "Afficher"} le mot de passe
                  </button>

                  <Button style={{width: "190px", margin: "auto"}} onClick={handleCreatePatient} className={"mt-2"}
                          color="default" type="button">
                    <span className="btn-inner--icon">
                      <i className="ni ni-single-02"/>
                   </span>
                    <i style={{position: "fixed", left: "25px"}} className="ni ni-fat-add"/>
                    <span style={{marginLeft: "15px"}} className="btn-inner--text">Créer le compte</span>
                  </Button>
                </Row>
                {isError.email &&
                <h5 style={{color: "#D8636F", marginTop: "20px", textAlign: "center"}}>Cette adresse email existe déjà
                  ou est invalide</h5>}
                {isError.empty &&
                <h5 style={{color: "#D8636F", marginTop: "20px", textAlign: "center"}}>Vous devez remplir tous les
                  champs du formulaire</h5>}
                {isError.password === "notSame" &&
                <h5 style={{color: "#D8636F", marginTop: "20px", textAlign: "center"}}>
                  La confirmation du mot de passe ne correspond pas
                </h5>}
                {isError.password === "validation" &&
                <h5 style={{color: "#D8636F", marginTop: "20px", textAlign: "center"}}>
                  Le mot de passe demande au minimum :
                  <br/> 8 caractères, 1 majuscule, 1 minuscule, 1 chiffre
                </h5>}
                {isSuccess &&
                <h5 style={{color: "#5cb85c", marginTop: "20px", textAlign: "center"}}>Votre nouveau patient a été créé
                  avec succès ! </h5>}
              </div>
            </Form>
          </CardBody>
        </Card>
      </div>
    </Modal>
  )
}

export const ModalCreateTherapist = ({toggle, callback, closeModal}) => {
  const [isOpen, setOpen] = useState(true);
  const [newTherapist, setNewTherapist] = useState({
    last_name: "",
    first_name: "",
    email: "",
    phone: "",
    city: "",
    status: "libéral"

  });
  const [isError, setError] = useState({
    email: false,
    empty: false,
  })
  const [isSuccess, setSuccess] = useState(false)

  useEffect(() => {
    if (toggle) {
      if (toggle.email) {
        setNewTherapist(toggle)
        setError({...isError, email: toggle.code || true})
      } else (setOpen(true))
    }

  }, [toggle]);

  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        setOpen(false)
      }, 2000)
    }
  }, [isSuccess])

  const handleFormChange = (e) => {
    e.preventDefault();

    if(e.target.name == 'email'){
      setNewTherapist({
        ...newTherapist,
        [e.target.name]: e.target.value.toLowerCase()
      })
    } else {
      setNewTherapist({
        ...newTherapist,
        [e.target.name]: e.target.value

      })
    }

    if (isError.empty || isError.email)
      setError({password: false, empty: false, email: false})
  };

  const handleCreatePatient = (e) => {
    const isEmpty = !Object.values(newTherapist).some(x => (x !== ''));
    if (isEmpty) setError({...isError, empty: true})
    else {
      setError({password: false, empty: false})
      callback(newTherapist, e)
    }
  }

  return (
    <Modal
      className="modal-dialog-centered"
      size="sm"
      isOpen={isOpen}
    >
      <div className="modal-body p-0">
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent">
            <div style={{display: "flex", justifyContent: "space-between"}}>
              <h3 className="mb-0">Création d'un nouveau compte</h3>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={closeModal}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div style={{marginTop: "10px"}} className="text-muted">
              <small>Orthophoniste</small>
            </div>
          </CardHeader>
          <CardBody>

            <Form>
              <div>
                <Row>
                  <Col lg="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-username"
                      >
                        Nom
                      </label>
                      <Input
                        className="input-group-alternative"
                        required
                        onChange={handleFormChange}
                        name="last_name"
                        id="input-username"
                        type="text"
                        defaultValue={newTherapist.last_name}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-username"
                      >
                        Prénom
                      </label>
                      <Input
                        className="input-group-alternative"
                        required
                        onChange={handleFormChange}
                        name="first_name"
                        id="input-username"
                        type="text"
                        defaultValue={newTherapist.first_name}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-email"
                      >
                        Adresse email
                      </label>
                      <Input
                        style={isError.email ? {border: "1px solid #D8636F"} : null}
                        className="input-group-alternative"
                        required
                        autoCapitalize="none"
                        onChange={handleFormChange}
                        name="email"
                        id="input-email"
                        type="email"
                        defaultValue={newTherapist.email}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-first-name"
                      >
                        Téléphone
                      </label>
                      <Input
                        className="input-group-alternative"
                        required
                        onChange={handleFormChange}
                        name="phone"
                        id="input-phone"
                        type="text"
                        defaultValue={newTherapist.phone}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-email"
                      >
                        Ville
                      </label>
                      <Input
                        className="input-group-alternative"
                        required
                        onChange={handleFormChange}
                        name="city"
                        id="input-city"
                        type="text"
                        defaultValue={newTherapist.city}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <div style={{display: "flex", flexDirection: "column"}}>
                      <label
                        className="form-control-label"
                        htmlFor="input-email"
                      >
                        Statut
                      </label>
                      <select
                        style={{marginTop: "10px"}}
                        className="input-group-alternative"
                        name="status"
                        value={newTherapist.status}
                        onChange={handleFormChange}
                      >
                        <option value="libéral">Libéral</option>
                        <option value="salarié">Salarié</option>
                        <option value="mixte">Mixte</option>
                      </select>
                    </div>
                  </Col>

                  <Button style={{width: "190px", margin: "auto"}} onClick={handleCreatePatient} className={"mt-2"}
                          color="default" type="button">
                    <span className="btn-inner--icon">
                      <i className="ni ni-single-02"/>
                   </span>
                    <i style={{position: "fixed", left: "25px"}} className="ni ni-fat-add"/>
                    <span style={{marginLeft: "15px"}} className="btn-inner--text">Créer le compte</span>
                  </Button>
                </Row>
                {isError.email &&
                <h5 style={{color: "#D8636F", marginTop: "20px", textAlign: "center"}}>Cette adresse email existe déjà
                  ou est invalide</h5>}
                {isError.empty &&
                <h5 style={{color: "#D8636F", marginTop: "20px", textAlign: "center"}}>Vous devez remplir tous les
                  champs du formulaire</h5>}
                {isError.password &&
                <h5 style={{color: "#D8636F", marginTop: "20px", textAlign: "center"}}>
                  Le mot de passe demande au minimum :
                  <br/> 8 caractères, 1 majuscule, 1 minuscule, 1 chiffre
                </h5>}
                {isSuccess &&
                <h5 style={{color: "#5cb85c", marginTop: "20px", textAlign: "center"}}>Votre nouveau patient a été créé
                  avec succès ! </h5>}
              </div>
            </Form>
          </CardBody>
        </Card>
      </div>
    </Modal>
  )
}

export const ModalCgu = ({user}) => {
  const [isOpen, setOpen] = useState(true);
  const {updateUser} = useAuth();

  const handleConfirmCgu = (e) => {
    e.preventDefault();

    let newUser = {has_signed_cgu: true};
    updateUser(newUser, user.id)
  }

  return (
    <Modal
      className="modal-dialog-centered modal-danger"
      contentClassName="bg-gradient-danger"
      isOpen={isOpen}
      size="lg"
    >
      <div className="modal-header">
        <h5 className="modal-title" id="modal-title-notification">
          Règles d’utilisation
        </h5>
      </div>
      <div className="modal-body">
        <div className="text-center">
          <h4 className="heading ">Bienvenue sur Gong !</h4><br/><br/>
          <p>
            Pour utiliser l’application, vous devez accepter nos règles d’utilisation, vous pouvez lire le document
            complet en cliquant sur le lien ci-dessous.
            Néanmoins comme c’est assez long, nous vous avons résumé les points important juste après :<br/><br/>
            1 - Nous vous mettons Gong à disposition gratuitement sans limitation de durée !<br/><br/>
            2 - Nous récoltons seulement les informations nécessaires au bon fonctionnement de Gong auprès de vous et
            vos patients<br/><br/>
            3 - Gong est conforme à la nouvelle loi européenne RGPD (et assure que vous le restiez en tant que
            professionnel de santé ! <span aria-label="clin-oeil" role="img">😉</span> )<br/><br/>
            4 - Les données d’utilisation sont utilisées de manière anonyme et uniquement pour améliorer Gong (nous ne
            vendons, prêtons, donnons accès aux données à personne d’autre, et encore heureux 😉 )<br/><br/>
            <a rel="noopener noreferrer" target="_blank"  href={"https://drive.google.com/file/d/1c-9wHdTf2Eo0M9JdiP4nFLJjlZOsRTib/view?usp=sharing"} style={{fontWeight: "bold", textDecoration: "underline", color: "white"}}>
              Lire les conditions générales d’utilisation complètes ici (lien url)
            </a>
          </p>
        </div>
      </div>
      <div className="modal-footer">
        <Button
          className="btn-white"
          color="default"
          type="button"
          onClick={handleConfirmCgu}
        >
          J'accepte les règles d'utilisation
        </Button>
        <Button
          className="text-white ml-auto"
          color="link"
          data-dismiss="modal"
          type="button"
          onClick={() => setOpen(false)}
        >
          Je refuse
        </Button>
      </div>
    </Modal>
  )
}

export const ModalHNAcountNotAuthorized = ({toggle}) => {
  const [isOpen, setOpen] = useState(false);

  console.log("TOOGLE", toggle)

  useEffect(() => {
    if (toggle)
      setOpen(true)
  }, [toggle]);

  return (
      <Modal
          className="modal-dialog-centered modal-danger"
          contentClassName="bg-gradient-danger"
          isOpen={isOpen}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="modal-title-notification">
            Connexion non authorisée
          </h5>
          <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setOpen(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="py-3 text-center">
            <i className="ni ni-bell-55 ni-3x"/>
            <h4 className="heading mt-4">Attention !</h4>
            <p>
              Pour utiliser Gong, veuillez-vous connecter via la plateforme Happy Neuron. 😊
            </p>
          </div>
        </div>
        <div className="modal-footer">
          <Button className="btn-white" color="default" type="button" onClick={() => setOpen(false)}>
            Fermer
          </Button>
        </div>
      </Modal>
  )
}
