/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useEffect, useState} from "react";
import {Switch, Redirect} from "react-router-dom";
import {Container, Row, Col} from "reactstrap";
import styled from '@emotion/styled'
import {isLoggedIn, SignIn} from "../utils/auth";
import Login from "../views/examples/Login"
import {ModalHNAcountNotAuthorized} from "../components/Modal";
import RecoverPassword from "../views/examples/PasswordRecovery";
import ClipLoader from "react-spinners/ClipLoader";
import {getUser} from "../utils/API";

const Header = styled.div` 
  background: linear-gradient(40deg,#fbc939 0,#c38005 100%);
`;

const HappyNeuron = ({location, history}) => {
    const [showModal, setShowModal] = useState(false);
    const [showRecoverPassword, setShowRecoverPassword] = useState(false);
    const [titleText, setTitleText] = useState("Bienvenue !");
    const [corpsText, setCorpsText] = useState("Nous vous connectons à la plateforme");

    useEffect(() => {
        const params = getUrlParams();
        if(params.accessToken !== null && params.idToken !== null && params.refreshToken !== null && params.email != null) {
            console.log("login user");
            /*const tokens = {
                accessToken: params.accessToken,
                idToken: params.idToken,
                refreshToken: params.refreshToken,
            };*/

            //handleConnexion(tokens);

            handleSubmit(params.email);
        } else {
            console.log("no parameters");
        }
    }, []);

    const handleSubmit = (email) => {
        const userCredential = {
          email: email,
          password: "Gong69=" + email.substr(0,email.indexOf("@"))
        };

        SignIn(userCredential)
            .then(username => {
                console.log(username);
                getUser(username).then(response => {
                    const user = response.data.getUser;
                    handleSuccess();
                });

            })
            .catch(err => {
                if(err){
                    setTitleText("Oups");
                    setCorpsText("Une erreur est survenue, veuillez contacter notre support.");
                }
            });
    };

    /*const handleConnexion = (tokens) => {

        const decodedJWT = this.parseJwt(tokens.accessToken);

        console.log('Tokens : ', tokens);
        console.log('Decode : ', decodedJWT);

        const amplifyConfig = {
                API: {
                    graphql_endpoint: Constants.manifest.extra.AWS_APPSYNCGRAPHQLENDPOINT,
                    graphql_headers: () => ({
                        Authorization: tokens.accessToken
                    })
                }
            };

            Amplify.configure(amplifyConfig);

            AWS.config.update({
                region: Constants.manifest.extra.AWS_REGION,
                credentials: new AWS.CognitoIdentityCredentials({
                    IdentityPoolId: Constants.manifest.extra.AWS_IDENTITYPOOLID, // your identity pool id here
                    Logins: {
                        [`cognito-idp.${Constants.manifest.extra.AWS_REGION}.amazonaws.com/${Constants.manifest.extra.AWS_USERPOOLID}`]: tokens.idToken
                    }
                })
            });

            // refreshes credentials using AWS.CognitoIdentity.getCredentialsForIdentity()
            AWS.config.credentials.refresh(error => {
                if (error) {
                    console.error(error, "error refresh credential"); // fail silently
                } else {
                    Auth.currentCredentials()
                        .then(() => {
                            Storage.configure({
                                bucket: Constants.manifest.extra.AWS_S3_BUCKET_NAME, // REQUIRED -  Amazon S3 bucket
                                region: Constants.manifest.extra.AWS_S3_REGION, // OPTIONAL -  Amazon service region
                                identityPoolId: Constants.manifest.extra.AWS_IDENTITYPOOLID
                            });
                        })
                        .catch(err => {
                            console.log("error currentCredentials", err);
                        });
                }
            });

            aws.calls
                .getFullUser(decodedJWT.sub)
                .then(response => {
                    const currentUser = response.data.getUser;

                    const userRole = currentUser.roles[0].name;
                    const userManage = currentUser.manage;
                    aws.calls
                        .getFullUser(fromAppWeb.userId)
                        .then(userData => {
                            const userToDisplay = userData.data.getUser;
                            if (userRole === "admin") {
                                this.setUserSessionFromWeb(userToDisplay).catch(() =>
                                    this.setState({ errorWebLogin: true })
                                );
                            } else if (
                                userRole === "speechTherapist" &&
                                decodedJWT.sub !== fromAppWeb.userId
                            ) {
                                if (userManage.find(user => user.id === fromAppWeb.userId)) {
                                    this.setUserSessionFromWeb(userToDisplay).catch(() =>
                                        this.setState({ errorWebLogin: true })
                                    );
                                } else {
                                    this.setState({ errorWebLogin: true });
                                }
                            } else if (decodedJWT.sub === fromAppWeb.userId) {
                                this.setUserSessionFromWeb(userToDisplay).catch(() =>
                                    this.setState({ errorWebLogin: true })
                                );
                            } else {
                                this.setState({ errorWebLogin: true });
                            }
                        })
                        .catch(() => this.setState({ errorWebLogin: true }));
                })
                .catch(() => this.setState({ errorWebLogin: true }));

    };*/

    const handleSuccess = () => {
        history.push("/dashboard/index")
    }

    const handleHNAccount = () => {
        setShowModal(true);
    }

    const getUrlParams = () => {

        const a = location.search.split('&');
        console.log(JSON.stringify(a));
        const param = {};

        for (let i = 0; i < a.length; i++) {
            const kv = a[i].split('=');
            kv[0] = kv[0].replace('?', '');
            // eslint-disable-next-line prefer-destructuring
            param[kv[0]] = kv[1];
        }
        return param;
    }

    const handleDisplayRecoverPassword = () => {
        setShowRecoverPassword(true)
    }

    if (showRecoverPassword) return (
        <>
            <div className="main-content">
                <Header className="header py-7">
                    <Container>
                        <div className="header-body text-center mb-7">
                            <Row className="justify-content-center">
                                <Col lg="5" md="6">
                                    <h1 className="text-white">Mot de passe oublié ?</h1>
                                    <p className="text-lead text-white">
                                        Obtenez un code de vérification par email afin de définir un nouveau mot de passe
                                    </p>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </Header>
                {/* Page content */}
                <Container className="mt--8 pb-5">
                    <Row className="justify-content-center">
                        <Switch>
                            <RecoverPassword authSuccess={handleSuccess} handleHNAccount={handleHNAccount} />
                        </Switch>
                    </Row>
                </Container>
                <ModalHNAcountNotAuthorized toggle={showModal} />
            </div>
        </>
    )

    return (
        <>
            <div className="main-content">
                <Header className="header py-7">
                    <Container>
                        <div className="header-body text-center mb-7">
                            <Row className="justify-content-center">
                                <Col lg="5" md="6">
                                    <h1 className="text-white">{titleText}</h1>
                                    <p className="text-lead text-white">
                                        {corpsText}
                                    </p>
                                    <ClipLoader
                                        size={30}
                                        color={"#FFFFFF"}
                                        loading={true}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </Header>
                {/* Page content */}
                <Container className="mt--8 pb-5">
                    <Row className="justify-content-center">
                        <Switch>
                            <Login toggleRecoverPassword={handleDisplayRecoverPassword} authSuccess={handleSuccess} handleHNAccount={handleHNAccount} happyNeuronLogin={true} />
                        </Switch>
                    </Row>
                </Container>
            </div>
        </>
    );

}

export default HappyNeuron;
