/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useState} from "react";

import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col
} from "reactstrap";
import {SignIn, signOut} from "../../utils/auth";
import ClipLoader from "react-spinners/ClipLoader";
import {getUser} from "../../utils/API";
import "../../assets/css/styles.css"

const status = {
  success:
    {
      isSuccess: true,
      color: "success",
      message: "Connexion Réussie"
    }
  ,
  loading:
    {
      isLoading: true,
      color: "primary",
      message: "Connexion en cours"
    }
  ,
  initial:
    {
      isLoading: false,
      color: "default",
      message: "Se connecter"
    }
  ,
}

const Login = ({authSuccess, handleHNAccount, toggleRecoverPassword, happyNeuronLogin}) => {

  const [currentStatus, setCurrentStatus] = useState({
    ...status.initial,
    isLoading: false,
    isSuccess: false
  })
  const [passwordVisible, togglePasswordVisible] = useState(false)


  const [userCredential, setCredential] = useState({
    email: '',
    password: '',
  });

  const handleChange = (e) => {
    e.preventDefault();
    setCredential({
      ...userCredential,
      [e.target.name]: e.target.value
    })
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setCurrentStatus(status.loading);
    SignIn(userCredential)
      .then(username => {
        console.log(username);

        getUser(username).then(response => {
          const user = response.data.getUser;
          if(!user.happyNeuronId){
            setCurrentStatus(status.success);
            setTimeout(authSuccess(),2000);
          } else {
            signOut();
            setCurrentStatus(status.initial);
            handleHNAccount();
          }
        });
      })
      .catch(err => setCurrentStatus({
        ...status.initial,
        isLoading: false,
        isSuccess: false,
      }));
  };

  return (
    <>
      <div >
        <Card className="bg-secondary shadow border-0">
          <CardBody style={{display: "flex"}} className="px-lg-5 py-lg-5">
            <img
              style={{maxWidth: "210px"}}
              alt="Gong Logo"
              className="navbar-brand-img"
              src={require("assets/img/brand/logo-gong.png")}
            />
            {!happyNeuronLogin && (
              <Form style={{minWidth: "250px",display: "flex",flexDirection: "column",justifyContent: "space-evenly"}} role="form">
                <FormGroup >
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83"/>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input onChange={handleChange} onSubmit name="email" placeholder="Email" type="email" autoComplete="new-email"/>
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open"/>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input onChange={handleChange}
                           name="password"
                           placeholder="Mot de passe"
                           type={passwordVisible ? "text" : "password"}
                           autoComplete="new-password"/>
                  </InputGroup>
                  <Button
                      style={{border: "none", background: "none", fontSize: "0.9rem", marginTop: "5px", float: "right", color: "#adb5bd", cursor: "pointer", boxShadow: "none"}}
                      onClick={(e) => {
                        e.preventDefault()
                        togglePasswordVisible(!passwordVisible)
                      }}>
                    {passwordVisible ? "Masquer" : "Afficher"} le mot de passe
                  </Button>
                </FormGroup>
                <div className="text-right">
                  <Button
                      style={{display: "flex", alignItems: "center", float: "right"}}
                      disabled={currentStatus.isLoading}
                      onClick={handleSubmit}
                      color={currentStatus.color}
                      type="button"
                  >
                <span style={{marginRight: "15px"}}>
                  {currentStatus.message}
                </span>

                    {!currentStatus.isSuccess && !currentStatus.isLoading &&
                    <i style={{fontSize: "1.1rem", paddingTop: "1px"}} className="ni ni-bold-right"/>}

                    {currentStatus.isSuccess &&
                    <i style={{fontSize: "1.1rem", paddingTop: "1px"}} className="ni ni-check-bold"/>}

                    <ClipLoader
                        size={20}
                        color={currentStatus.color}
                        loading={currentStatus.isLoading}
                    />
                    {/*{isSuccess && ("Connexion réussie")}
                {isLoading && ("Connexion en cours")}
                {!isLoading && !isSuccess ("Se connecter")}*/}
                  </Button>

                </div>
              </Form>
            )}
          </CardBody>
        </Card>
        {!happyNeuronLogin && (
          <Row className="mt-3">
            <Col xs="6">
              <button
                className="button-recover"
                onClick={() => toggleRecoverPassword()}
              >
                <small>Mot de passe oublié?</small>
              </button>
            </Col>
            <Col className="text-right" xs="6">
              <a
                className="text"
                rel="noopener noreferrer" target="_blank"
                href="https://gong-communication.fr/"
                onClick={e => e.preventDefault()}
              >
                <small>Je veux m'inscrire</small>
              </a>
            </Col>
          </Row>
        )}
      </div>
    </>
  );
}

export default Login;
