import React, {useEffect, useState} from 'react';
import useAuth from "../hooks/useAuth";

const TabletAdmin = ({location,history}) => {
  const {user, allUsers} = useAuth();
  const [userSource, setUserSource] = useState(false);
  const rootUrl = 'https://d2kpn02w8ai5hx.cloudfront.net/#////auth/login';
  const fullUrl = `${rootUrl}/?user=${userSource.id}&accessToken=${userSource.accessToken}&idToken=${userSource.idToken}`

  useEffect(() => {
    let searchParams = new URLSearchParams(location.search)
    searchParams = searchParams.get("user")
    if (!userSource && user.id) {
      if (searchParams) {
        let isValidUser = false
        if (user.roles[0].name === "speechTherapist"){
          isValidUser = user.manage.find(e => e.id === searchParams)
        }
        else if (user.isAdmin) isValidUser = allUsers.find(e => e.id === searchParams)
        if (isValidUser) setUserSource({id: isValidUser.id, idToken: user.idToken, accessToken: user.accessToken})
        else history.push("/dashboard/index")
      }
      else setUserSource(user)
    } else if (user.id) setUserSource(user)
  }, [location]);

    return (
      <div>
        {
          userSource.id &&
          <div
            style={{height: '100vh', width: '100%'}}
            dangerouslySetInnerHTML={{__html: `<iframe src=${fullUrl} style='width: 100%;height: 100%; background: white;' frameBorder='0' />`}}
          />
        }
      </div>
    );
}

export default TabletAdmin;
