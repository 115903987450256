// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

//Prod
const awsmobile = {
  aws_project_region: 'eu-central-1',
  aws_cognito_identity_pool_id: 'eu-central-1:58dbacc4-4b1f-4b7a-9287-3d441a241033',
  aws_cognito_region: 'eu-central-1',
  aws_user_pools_id: 'eu-central-1_zBvurBQ2o',
  aws_user_pools_web_client_id: '6rbdf70vd294i5k544hpgap92l',
  oauth: {},
  aws_appsync_graphqlEndpoint:
    'https://fj3cd3xt6bd5xkonafyqlckq5u.appsync-api.eu-central-1.amazonaws.com/graphql',
  aws_appsync_region: 'eu-central-1',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_appsync_apiKey: 'da2-7yflnwylgrdpvdqtbosu6uikk4',
  "aws_user_files_s3_bucket": "gongimageprod",
  "aws_user_files_s3_bucket_region": "eu-central-1"
}

//Pre-prod
/*const awsmobile = {
  aws_project_region: 'eu-central-1',
  aws_cognito_identity_pool_id:
    'eu-central-1:58dbacc4-4b1f-4b7a-9287-3d441a241033',
  aws_cognito_region: 'eu-central-1',
  aws_user_pools_id: 'eu-central-1_zBvurBQ2o',
  aws_user_pools_web_client_id: '6rbdf70vd294i5k544hpgap92l',
  oauth: {},
  aws_appsync_graphqlEndpoint:
    'https://fj3cd3xt6bd5xkonafyqlckq5u.appsync-api.eu-central-1.amazonaws.com/graphql',
  aws_appsync_region: 'eu-west-1',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_appsync_apiKey: 'da2-7yflnwylgrdpvdqtbosu6uikk4',
  "aws_user_files_s3_bucket": "images-gong-expo",
  "aws_user_files_s3_bucket_region": "eu-central-1"
}*/

export default awsmobile



/*# Serverless lambda
REACT_APP_API_URL_CANCEL_SUBSCRIPSTION = https://e7ex9uznsl.execute-api.eu-west-1.amazonaws.com/latest/cancel-subscription
REACT_APP_API_URL_PAYEMENT = https://e7ex9uznsl.execute-api.eu-west-1.amazonaws.com/latest

# Iframe Tablet ReactNative
REACT_APP_IFRAME_TABLET = https://d1galzmjdb9t2c.cloudfront.net

# Stripe public key
REACT_APP_STRIPE_PK = pk_test_K7W7dQWSCgOl25pLCkoRN77w*/
