/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useEffect, useState} from "react";
import {Redirect} from "react-router-dom";
import {
  Badge,
  Card,
  CardHeader,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Button,
  Table,
  Container,
  Row,
} from "reactstrap";
import styled from '@emotion/styled'
import {HeaderPrimaryBtn} from "../../components/Headers/Header";
import BadgeAbonnement from "../../components/BadgeAbonnement";
import BadgeTherapist from "../../components/BadgeTherapist";
import useAuth from "../../hooks/useAuth";
import {ModalCreateTherapist} from "../../components/Modal";
import onSearch from "../../utils/search";

const Tr = styled.tr`
  transition: 0.2s;
  &:hover {
    background-color: #c8860914;
    transition: 0.2s;
  }
`

const ListUsersAdmin = ({location, history}) => {

  const {allUsers, user, createNewTherapist, CleanErrorTherapist} = useAuth();
  const [query, setQuery] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [listPatients, setListPatients] = useState(false);
  const [createForm, toggleForm] = useState(false);

  useEffect(() => {
    if (user.errorCreateTherapist)
      toggleForm(user.errorCreateTherapist)
  }, [user.errorCreateTherapist])

  useEffect(() => {
    return () => {
      if (user.errorCreateTherapist)
        CleanErrorTherapist()
    }
  })

  const handleOnChange = (e) => {
    e.preventDefault();
    setQuery(e.target.value);
    if (query.length > 1) {
      const res = onSearch(query, allUsers, ["first_name", "last_name", "email", "phone"], allUsers);
      setSearchResult(res)
    }
    else {
      setSearchResult([])
    }
  };

  const handleOnPaste = (e) => {
    e.preventDefault();
    setQuery(e.clipboardData.getData('Text'));
    const res = onSearch(e.clipboardData.getData('Text'), allUsers, ["first_name", "last_name", "email", "phone"], allUsers);
    setSearchResult(res)
  };

  const toggleListPatients = (e, user) => {
    e.preventDefault();
    let listPatients = allUsers.filter(e => user.manage.some(s => s.id === e.id));
    setListPatients(listPatients)
  };

  const handleReturn = (e) => {
    e.preventDefault();
    setListPatients([])
  }

  const handleModalCreateTherapist = (e) => {
    e.preventDefault();
    toggleForm(!createForm)
  };

  const createTherapist = (newTherapist, e) => {
    e.preventDefault();
    createNewTherapist(newTherapist)
  }

  const handleOnSubmit = (e) => {
    e.preventDefault()
    if (query.length > 1) {
      const res = onSearch(query, allUsers, ["first_name", "last_name", "email", "phone"], allUsers);
      setSearchResult(res)
    }
  }

  return user.isAdmin ? (
    <>
      {createForm && (
        <ModalCreateTherapist closeModal={handleModalCreateTherapist} toggle={createForm} callback={createTherapist}/>
      )}
      <HeaderPrimaryBtn>
        <Button onClick={(e) => handleModalCreateTherapist(e)} style={{marginBottom: "15px", cursor: "pointer"}} className="btn-icon btn-3" color="secondary" type="button">
          <span className="btn-inner--icon">
            <i className="ni ni-single-02" />
          </span>
          <i style={{position: "fixed", left: "25px"}} className="ni ni-fat-add" />
          <span style={{marginLeft: "15px"}} className="btn-inner--text">Ajouter un orthophoniste</span>
        </Button>
      </HeaderPrimaryBtn>
      {/* Page content */}
      <Container style={{marginTop: "-11vh"}} fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader style={{display: "flex", alignItems: "center"}} className="border-0">
                {listPatients.length > 0 ? (<div>
                      <h3 className="mb-0">Liste des patients</h3>
                      <br></br>
                      <Button onClick={(e) => handleReturn(e)} color="default" size="sm" type="button">
                        <i style={{fontSize: "15px", verticalAlign: "middle", marginRight: "10px"}}
                           className="ni ni-bold-left"/>Revenir en arrière
                      </Button>
                    </div>
                  )
                  : (<h3 className="mb-0">Liste des utilisateurs</h3>)}
                <Form onSubmit={handleOnSubmit} onClick={(e) => e.preventDefault()}
                      className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto">
                  <FormGroup className="mb-0">
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i style={{color: "black"}} className="fas fa-search"/>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        onPaste={handleOnPaste}
                        onChange={handleOnChange}
                        style={{color: "black"}}
                        placeholder="Search"
                        type="search"
                        defaultValue={query}
                      />
                    </InputGroup>
                  </FormGroup>
                </Form>
              </CardHeader>
              {listPatients.length > 0 ? (
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                  <tr>
                    <th scope="col">Utilisateur</th>
                    <th scope="col">Adresse Email</th>
                    <th scope="col">Téléphone</th>
                    <th scope="col">Abonnement</th>
                  </tr>
                  </thead>
                  <tbody>
                  {listPatients.map((user, id) => {
                    return (
                      <Tr key={id}>
                        <td style={{
                          display: 'flex',
                          alignItems: "center",
                          justifyContent: "flex-start",
                          height: "48px"
                        }}>
                          <Badge style={{background: "#172b4e", marginRight: "15px", cursor: "pointer", display: "flex", alignItems: "center", justifyContent: "center"}}
                                 onClick={(e) => history.push("/dashboard/index?user=" + user.id)} color={"#162B4D"}>
                            <i style={{color: "white", fontSize: "15px"}} className="ni ni-tv-2"/>
                            <span style={{color: "white"}}>VOIR</span>
                          </Badge>
                          {user.first_name} {user.last_name}
                        </td>
                        <td>{user.email}</td>
                        <td>{user.phone}</td>
                        <td>
                          <Badge color="" className="badge-dot">
                            {user.roles[0].name === "patient" && <BadgeAbonnement user={user}/>}
                          </Badge>
                        </td>
                      </Tr>
                    )
                  })
                }
                  </tbody>
                </Table>
              ) : (
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                  <tr>
                    <th scope="col">Utilisateur</th>
                    <th scope="col">Adresse Email</th>
                    <th scope="col">Téléphone</th>
                    <th scope="col">Abonnement</th>
                  </tr>
                  </thead>
                  <tbody>
                  {console.log("Nombre user : ", searchResult.length > 0)}
                  {searchResult?.length > 0 && searchResult?.map((user, id) => {
                    return (
                      <Tr key={id}>
                        <td style={{
                          display: 'flex',
                          alignItems: "center",
                          justifyContent: "flex-start",
                          height: "48px"
                        }}>
                          <Badge style={{background: "#172b4e", marginRight: "15px", cursor: "pointer", display: "flex", alignItems: "center", justifyContent: "center"}}
                                 onClick={(e) => history.push("/dashboard/index?user=" + user.id)} color={"#162B4D"}>
                            <i style={{color: "white", fontSize: "15px"}} className="ni ni-tv-2"/>
                            <span style={{color: "white"}}>VOIR</span>
                          </Badge>
                          {user.roles[0].name === "speechTherapist" && user.manage &&
                          <BadgeTherapist viewListPatients={(e, user) => toggleListPatients(e, user)} user={user}/>}
                          {user.first_name} {user.last_name}
                        </td>
                        <td>{user.email}</td>
                        <td>{user.phone}</td>
                        <td>
                          <Badge color="" className="badge-dot">
                            {user.roles[0].name === "patient" && <BadgeAbonnement user={user}/>}
                          </Badge>
                        </td>
                      </Tr>
                    )
                  })
                  }
                  {searchResult?.length < 1 && allUsers.map((user, id) => {
                    return (
                      <Tr key={id}>
                        <td style={{
                          display: 'flex',
                          alignItems: "center",
                          justifyContent: "flex-start",
                          height: "48px"
                        }}>
                          <Badge style={{background: "#172b4e", marginRight: "15px", cursor: "pointer", display: "flex", alignItems: "center", justifyContent: "center"}}
                                 onClick={(e) => history.push("/dashboard/index?user=" + user.id)} color={"#162B4D"}>
                            <i style={{color: "white", fontSize: "15px"}} className="ni ni-tv-2"/>
                            <span style={{color: "white"}}>VOIR</span>
                          </Badge>
                          {user.roles[0].name === "speechTherapist" && user.manage &&
                          <BadgeTherapist viewListPatients={(e, user) => toggleListPatients(e, user)} user={user}/>}
                          {user.first_name} {user.last_name}
                        </td>
                        <td>{user.email}</td>
                        <td>{user.phone}</td>
                        <td>
                          <Badge color="" className="badge-dot">
                            {user.roles[0].name === "patient" && <BadgeAbonnement user={user}/>}
                          </Badge>
                        </td>
                        <td>
                        </td>
                      </Tr>
                    )
                  })
                  }
                  </tbody>
                </Table>
              )}
            </Card>
          </div>
        </Row>
      </Container>
    </>
  ) : <Redirect from={"/dashboard/users"} to="/dashboard/index"/>;
};

export default ListUsersAdmin;
