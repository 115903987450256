/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useEffect, useState} from "react";
import {Route, Switch, Redirect} from "react-router-dom";

import {Container} from "reactstrap";

import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import routes from "../routes";
import useAuth from "../hooks/useAuth";
import {isLoggedIn} from "../utils/auth";
import '../assets/css/admin.css';

import {ModalCgu} from "../components/Modal";
import Loader from "../components/loader";

import ModalVideo from 'react-modal-video';

const Admin = ({history, location, match}) => {
  const {user, setUser} = useAuth();
/*
  const [modalCgu, toggleModalCgu] = useState(false)
*/
  const [isVideoModalVisible, setIsVideoModalVisible] = useState(false);
  const [currentVideoId, setCurrentVideoId] = useState('');

  useEffect(() => {
    if (!user.isAuth && isLoggedIn()) {
      setUser(isLoggedIn())
    }
/*    if (!user.hasSignedCgu) {
      toggleModalCgu(!modalCgu)
    }*/
  }, []);

  const getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.layout === "/dashboard") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  const getBrandText = path => {
    for (let i = 0; i < routes.length; i++) {
      if (
        location.pathname.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };

  const showVideo = videoUrl => {
    console.log("Video url : " + videoUrl);
    setCurrentVideoId(videoUrl);
    setIsVideoModalVisible(true);
  };

  const hideVideo = () => {
    setIsVideoModalVisible(false);
  }

  if (user.isLoading && isLoggedIn()) {
    return <Loader size={150}/>
  }

  return isLoggedIn() ? (
    <>
      {!user.has_signed_cgu && (
        <ModalCgu user={user} />
      )}

      <Sidebar
        userRole={user.roles[0].name}
        history={history}
        location={location}
        match={match}
        routes={routes}
        user={user}
        videoFunction={showVideo}
        logo={{
          innerLink: "/dashboard",
          imgSrc: require("assets/img/brand/logo-gong.png"),
          imgAlt: "..."
        }}
      />

      <div className="main-content">
        <ModalVideo channel='youtube' autoplay isOpen={isVideoModalVisible} videoId={currentVideoId} onClose={() => hideVideo()} />
        {location.pathname !== "/dashboard/index" && (
          <AdminNavbar
            user={user}
            history={history}
            location={location}
            match={match}
            brandText={getBrandText(location.pathname)}
          />
        )}
        <Switch>
          {getRoutes(routes)}
          <Redirect from="/" to="/dashboard/index"/>
        </Switch>
        <Container fluid>
        </Container>
      </div>
    </>
  ) : <Redirect from={location.pathname} to="/login"/>;
};

export default Admin;
