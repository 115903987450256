const INITIAL_STATE = []

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_PATIENT_LIST':
      return action.allPatients
      ;
    case 'ADD_PATIENT_LIST':
      return [
        ...state,
        {...action.newPatient}
      ]
    case 'CLEAN_THERAPIST':
      return []
    default:
      return state
  }
}
