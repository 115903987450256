/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

import styled from "@emotion/styled";

const PrimaryBlock = styled.div` 
  background: linear-gradient(87deg, #172b4d 0, #1a174d 100%) !important;
  text-align: center;
  padding-top: 8vh;
  padding-bottom: 15vh
`

const DefaultBlock = styled.div` 
  background: linear-gradient(40deg,#fbc939 0,#c38005 100%);
`

export const HeaderPrimary = () => {
  return (
    <PrimaryBlock className="header pb-8 pt-5 pt-md-8"/>
  )
}

export const HeaderPrimaryBtn = ({children}) => {
  return (
    <PrimaryBlock className="header">{children}</PrimaryBlock>
  )
}

export const HeaderDefault = () => {
  return (
    <DefaultBlock className="header pb-8 pt-5 pt-md-8"/>
  )
}
