export const setUserAction = user => ({
  type: 'SET_USER',
  user,
});

export const setLoadingAction = () => ({
  type: 'SET_LOADING',
});

export const unsetLoadingAction = () => ({
  type: 'UNSET_LOADING',
});

export const setErrorCreatePatient = (newPatient, err) => ({
  type: 'SET_ERROR_CREATE_PATIENT',
  newPatient, err
});

export const setErrorCreateTherapist = (newTherapist, err) => ({
  type: 'SET_ERROR_CREATE_THERAPIST',
  newTherapist, err
});

export const unsetErrorCreatePatient = () => ({
  type: 'UNSET_ERROR_CREATE_PATIENT',
})

export const unsetErrorCreateTherapist = () => ({
  type: 'UNSET_ERROR_CREATE_THERAPIST',
})

export const cleanSessionUser = () => ({
  type: 'CLEAN_SESSION_USER'
})
