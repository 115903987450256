import React from "react";
import moment from "moment";

const BadgeAbonnement = ({user}) => {
  const trial = user.license[0].name === "patientTrialLicense";
  const license = user.license[0].name === "patientLicense";
  const cancel = user.license[0].name === "canceled";
  const endTrial = user.license[0].end < moment().format('X');

  if (endTrial && trial) {
    return (
      <div>
        <i className="bg-danger"/>
        Essai terminé
      </div>
    )
  } else if (!endTrial && trial) {
    return (
      <div>
        <i className="bg-info"/>
        Periode d'essai
      </div>
      )
  }
  else if (license && !cancel) {
    return (
      <div>
        <i className="bg-success"/>
        Abonné
      </div>
    )
  }
  else if (cancel) {
    return (
      <div>
        <i className="bg-danger"/>
        Désabonné
      </div>
    )
  } 
  return <div/>
}

export default BadgeAbonnement
