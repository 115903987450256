/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useEffect, useState} from "react";

import Tablet from "../../components/TabletAdmin"
import useAuth from "../../hooks/useAuth";


const BaseItems = ({location, history}) => {
    const [userSource, setUserSource] = useState(false)
    const {user} = useAuth()

    useEffect(() => {
        if (!userSource){
            if (user.id) {
                setUserSource(user)
            }
        }
    }, [user])

    return userSource.id ? (
        <>
            <Tablet location={location} history={history} userSource={user} />
        </>
    ) : <div>LOADING</div>;
}

export default BaseItems;
