import { API, graphqlOperation } from 'aws-amplify';

export const getUser = async(id) => {
  console.log("Graph QL getUser : " + id);
  const user = await API.graphql(graphqlOperation(queryGetUser, { id }));
  console.log(user);
  return user
};

export const getAllUsers = async() => {
  const allUsers = await API.graphql(graphqlOperation(queryGetAllUsers));
  return allUsers
};

export const updateProfile = async(user, id) => {
  const profile = await API.graphql(graphqlOperation(queryUpdateProfile,
    {
      id: id,
      user: user
    }
    ));
  return profile
};

export const createUser = async(user, id) => {
  const profile = await API.graphql(graphqlOperation(queryCreateUser,
    {
      id: id,
      user: user
    }
  ));
  return profile
};

export const updateTherapist = async(id, patients) => {
  const profile = await API.graphql(graphqlOperation(queryUpdateTherapist,
    {
      id: id,
      user: {
        manage: patients
      }
    }
  ));
  return profile
};

export const queryGetUser = /* GraphQL */ `
  query ListUsers(
    $id: ID!
  ) {
    getUser(id: $id) {
        id
        address
        city
        configs {
          display_card_number
          display_horizontal
          display_signature
          name
          display_yes_no
          picture_style
          speech_on_each_card
          speech_speed
          speech_voice_tonality
        }
        createdAt
        email
        first_name
        has_signed_cgu
        happyNeuronId
        last_name
        manage {
          id
          role
        }
        license {
          begin
          description
          duration
          name
          end
        }
        pseudo
        phone
        roles {
          permissions
          name
        }
        status
        updatedAt
        zip_code
      }
  }
`;

export const queryGetAllUsers = /* GraphQL */ `
  query getAllUser{
    getAllUser {
      id
      last_name
      first_name
      configs {
        name
        display_card_number
        display_horizontal
        display_signature
        display_yes_no
        speech_on_each_card
        speech_speed
        speech_voice_tonality
        picture_style
      }
      license {
        name
        duration
        description
        begin
        end
      }
      roles {
        permissions
        name
      }
      manage {
        role
        id
      }
      email
      status
      city
      phone
      happyNeuronId
      zip_code
      address
      has_signed_cgu
    }
  }
`;

export const queryUpdateProfile = `
  mutation putUser (
    $id: ID!,
    $user: UserInput
  ) {
    putUser(id: $id, user: $user) {
      id
      last_name
      first_name
      email
      phone
      address
      city
      zip_code
      has_signed_cgu
    }
  }
`

export const queryCreateUser = `
  mutation putUser (
    $id: ID!,
    $user: UserInput
  ) {
    putUser(id: $id, user: $user) {
      id
    }
  }
`

export const queryUpdateTherapist = `
  mutation putUser (
    $id: ID!,
    $user: UserInput
  ) {
    putUser(id: $id, user: $user) {
      id
    }
  }
`

export const queryUpdateUser = `
  mutation putUser (
    $id: ID!,
    $user: UserInput
  ) {
    putUser(id: $id, user: $user) {
      id
    }
  }
`
