/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useState} from "react";

import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
} from "reactstrap";
import {sendCodeChangePassword, setNewPassword, SignIn, signOut} from "../../utils/auth";
import ClipLoader from "react-spinners/ClipLoader";
import "../../assets/css/styles.css"
import {getUser} from "../../utils/API";

const status = {
  success:
    {
      isSuccess: true,
      color: "success",
      message: "Connexion Réussie"
    }
  ,
  successRecover:
      {
        isSuccess: true,
        color: "success",
        message: "Succès ! connexion en cours..."
      }
  ,
  loading:
    {
      isLoading: true,
      color: "primary",
      message: "Connexion en cours"
    }
  ,
  loadingRecover:
      {
        isLoading: true,
        color: "primary",
        message: "Confirmation en cours"
      }
  ,
  recover:
      {
        isLoading: false,
        color: "default",
        message: "Choisir ce mot de passe"
      }
  ,
  initial:
    {
      isLoading: false,
      color: "default",
      message: "Envoyer le code"
    }
  ,
}

const RecoverPassword = ({authSuccess, handleHNAccount}) => {

  const [isError, setError] = useState(false)
  const [displayCode, setDisplayCode] = useState(false)
  const [currentStatus, setCurrentStatus] = useState({
    ...status.initial,
    isLoading: false,
    isSuccess: false,
  })
  const [passwordVisible, togglePasswordVisible] = useState(false)


  const [userCredential, setCredential] = useState({
    email: '',
    password: '',
    rePassword: '',
    code: ''
  });

  const handleChange = (e) => {
    e.preventDefault();
    setCredential({
      ...userCredential,
      [e.target.name]: e.target.value
    })
  };

  const handleSendCode = (e) => {
    e.preventDefault();
    if (userCredential.email !== "") {
      setCurrentStatus(status.loading);
      sendCodeChangePassword(userCredential.email)
          .then(() => {
            setDisplayCode(true)
            setCurrentStatus(status.recover);
          })
          .catch(() => {
            setCurrentStatus(status.initial);
            setError("Cette adresse email n'existe pas")
          })
    }
    else setError("Veuillez saisir une adresse email")
  };

  const handleSubmitVerification = (e) => {
    e.preventDefault();
    if (userCredential.password !== "" && userCredential.rePassword !== "" && userCredential.code !== "") {
      setCurrentStatus(status.loadingRecover);
      if (userCredential.password !== userCredential.rePassword) {
        setError("Les mots de passes que vous avez saisis ne correspondent pas. Veuillez saisir votre mot de passe dans le champ de mot de passe et confirmez votre saisie dans le champ de confirmation du mot de passe.")
      }
      else if (!userCredential.password.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z=!#.]{8,}$/))
        setError("Le mot de passe demande au minimum : 8 caractères, 1 majuscule, 1 minuscule, 1 chiffre")
      else {
        setNewPassword(userCredential.code, userCredential.email, userCredential.password)
            .then(() => {
              setCurrentStatus(status.successRecover);
              SignIn(userCredential)
                  .then(username => {
                    getUser(username).then(response => {
                      const user = response.data.getUser;
                      if (!user.happyNeuronId) {
                        setCurrentStatus(status.success);
                        setTimeout(authSuccess(), 4000);
                      } else {
                        signOut();
                        setCurrentStatus(status.initial);
                        handleHNAccount();
                      }
                    });

                  })
            })
            .catch(() => {
              setError("Le code de vérification n'est pas valide.")
              setCurrentStatus(status.recover);
            })
      }
    }
    else setError("Vous devez renseigner toutes les informations")
  };

  if (displayCode) return (
      <>
        <div >
          <Card className="bg-secondary shadow border-0">
            <CardBody style={{display: "flex"}} className="px-lg-5 py-lg-5">
              <Form id={"form-recover"}  style={{minWidth: "250px",display: "flex",flexDirection: "column",justifyContent: "space-evenly"}} role="form">
                <FormGroup id={"form-recover-group"}  >
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-curved-next"/>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input id={Date.now()} value={userCredential.code} onChange={handleChange} autoComplete="code" type={"text"} name="code" placeholder="Code de vérification"/>
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open"/>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input onChange={handleChange} name="password" placeholder="Mot de passe"
                           type={passwordVisible ? "text" : "password"} autoComplete="new-password"/>
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open"/>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input onChange={handleChange} name="rePassword" placeholder="Confirmer le mot de passe"
                           type={passwordVisible ? "text" : "password"} autoComplete="new-password"/>
                  </InputGroup>
                  <button
                      style={{border: "none", background: "none", fontSize: "0.9rem", marginTop: "5px", float: "right", color: "#adb5bd", cursor: "pointer"}}
                      onClick={(e) => {
                        e.preventDefault()
                        togglePasswordVisible(!passwordVisible)
                      }}>
                    {passwordVisible ? "Masquer" : "Afficher"} le mot de passe
                  </button>
                </FormGroup>
                {isError && (
                    <div className={"wrapper-error-recover"}>
                      <p className={"error-recover"}>{isError}</p>
                    </div>
                )}
                <div className="text-right">
                  <Button
                      style={{display: "flex", alignItems: "center", float: "right"}}
                      disabled={currentStatus.isLoading}
                      onClick={handleSubmitVerification}
                      color={currentStatus.color}
                      type="button"
                  >
                  <span style={{marginRight: "15px"}}>
                    {currentStatus.message}
                  </span>

                    {!currentStatus.isSuccess && !currentStatus.isLoading &&
                    <i style={{fontSize: "1.1rem", paddingTop: "1px"}} className="ni ni-bold-right"/>}

                    {currentStatus.isSuccess &&
                    <i style={{fontSize: "1.1rem", paddingTop: "1px"}} className="ni ni-check-bold"/>}

                    <ClipLoader
                        size={20}
                        color={currentStatus.color}
                        loading={currentStatus.isLoading}
                    />
                  </Button>

                </div>
              </Form>
            </CardBody>
          </Card>
        </div>
      </>
  );

  return (
    <>
      <div >
        <Card className="bg-secondary shadow border-0">
          <CardBody style={{display: "flex"}} className="px-lg-5 py-lg-5">
            <Form style={{minWidth: "250px",display: "flex",flexDirection: "column",justifyContent: "space-evenly"}} role="form">
              <FormGroup >
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83"/>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input onChange={handleChange} name="email" placeholder="Email" type="email" autoComplete="new-email"/>
                </InputGroup>
              </FormGroup>
              {isError && (
                  <div className={"wrapper-error-recover"}>
                    <p className={"error-recover"}>{isError}</p>
                  </div>
              )}
              <div className="text-right">
                <Button
                  style={{display: "flex", alignItems: "center", float: "right"}}
                  disabled={currentStatus.isLoading}
                  onClick={handleSendCode}
                  color={currentStatus.color}
                  type="button"
                >
                  <span style={{marginRight: "15px"}}>
                    {currentStatus.message}
                  </span>

                  {!currentStatus.isSuccess && !currentStatus.isLoading &&
                  <i style={{fontSize: "1.1rem", paddingTop: "1px"}} className="ni ni-bold-right"/>}

                  {currentStatus.isSuccess &&
                  <i style={{fontSize: "1.1rem", paddingTop: "1px"}} className="ni ni-check-bold"/>}

                  <ClipLoader
                    size={20}
                    color={currentStatus.color}
                    loading={currentStatus.isLoading}
                  />
                </Button>

              </div>
            </Form>
          </CardBody>
        </Card>
      </div>
    </>
  );
};

export default RecoverPassword;
