const INITIAL_STATE = []

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_ALL_USERS':
      return action.allUsers;
    case 'CLEAN_ADMIN':
      return []
    default:
      return state
  }
}
