import { createStore, combineReducers } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import userReducer from './reducers/userReducer'
import adminReducer from './reducers/adminReducer'
import therapistReducer from './reducers/therapistReducer'

const isDev = process.env.NODE_ENV === 'development'
const store = createStore(
  combineReducers({
    user: userReducer,
    allUsers: adminReducer,
    allPatients: therapistReducer,
  }),
  isDev ? composeWithDevTools() : undefined
)

export default store
