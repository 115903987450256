/*eslint-disable*/
import React, { useState } from 'react';
import { NavLink as NavLinkRRD, Link } from 'react-router-dom';

// nodejs library to set properties for components
import { PropTypes } from 'prop-types';

import styled from '@emotion/styled';

// reactstrap components
import {
  Collapse,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col
} from 'reactstrap';

import useAuth from '../../hooks/useAuth';


const LogoImg = styled.img`
  max-width: 200px;
  @media (max-width: 768px) {
      position: inherit !important;
      width: 60px !important;
    }
`

const LogoLink = styled(Link)`
  margin: auto;
`


const ButtonSignOut = styled.button`
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 210px;
    left: 12px;
    position: fixed;
    bottom: 8px;
    cursor: pointer;
    border: none;
    @media (max-width: 768px) {
      position: inherit !important;
      width: 173px !important;
    }
`

var shotTrainingVideos = [
  'klBTL1w8g0w',
  'ys39yVjk7mY',
  'kW4XvJ5cHBo',
  '3liNpsQTKy0',
  'oGC7DPRp8aM',
  'yEj18izphuw',
];

const Sidebar = ({location, history, bgColor, routes, logo, userRole, user, videoFunction}) => {
  const {useSignOut} = useAuth()
  const [collapseOpen, setCollapseOpen] = useState(false)

/*  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }*/
  // toggles collapse between opened and closed (true/false)
  const toggleCollapse = () => {
    setCollapseOpen(!collapseOpen)
  };
  // closes the collapse
  const closeCollapse = () => {
    setCollapseOpen(false)
  };

  const showVideo = videoId => {
    videoFunction(shotTrainingVideos[videoId]);
  };

  // creates the links that appear in the left menu / Sidebar
  const createLinks = routes => {
    return routes.map((prop, key) => {
      if (prop.path === '/base-items' && !user.isAdmin )
        return
      if (prop.path === '/users' && !user.isAdmin )
        return
      if(prop.path === '/patients' && userRole === 'patient')
        return
      return  (
        <NavItem key={key}>
          <NavLink
            to={prop.layout + prop.path}
            tag={NavLinkRRD}
            onClick={closeCollapse}
            activeClassName="active"
          >
            <i className={prop.icon} />
            {prop.name}
          </NavLink>
        </NavItem>
      );
    });
  };

    return (
      <Navbar
        className="navbar-vertical fixed-left navbar-light bg-white"
        expand="md"
        id="sidenav-main"
      >
        <Container fluid>
          {/* Toggler */}
          <button
            className="navbar-toggler"
            type="button"
            onClick={toggleCollapse}
          >
            <span className="navbar-toggler-icon" />
          </button>
          {/* Brand */}
          {logo ? (
            <LogoLink to={"/dashboard/index"}>
              <LogoImg
                alt="Gong Logo"
                src={require("assets/img/brand/logo-gong.png")}
              />
            </LogoLink>
          ) : null}
          <span style={{textAlign: "center", marginTop: 20,fontSize: 14, fontWeight: 800, color: "#D8636F"}}>Rappel : Attention, Gong va fermer ses portes notre service client reste disponible jusqu’au 31/09/2024.</span>
          {/* User */}
          {/* Collapse */}
          <Collapse navbar isOpen={collapseOpen}>
            {/* Collapse header */}
            <div className="navbar-collapse-header d-md-none">
              <Row>
                {/*{logo ? (
                  <Col className="collapse-brand" xs="6">
                    {logo.innerLink ? (
                      <Link to={logo.innerLink}>
                        <LogoImg className={"logoMobile"} alt={logo.imgAlt} src={logo.imgSrc} />
                      </Link>
                    ) : (
                      <a href={logo.outterLink}>
                        <LogoImg className={"logoMobile"} alt={logo.imgAlt} src={logo.imgSrc} />
                      </a>
                    )}
                  </Col>
                ) : null}*/}
                <span style={{marginLeft: "15px"}} className="btn-inner--text">Ajouter un nouveau patient</span>
                <Col className="collapse-close" xs="6">
                  <button
                    className="navbar-toggler"
                    type="button"
                    onClick={toggleCollapse}
                  >
                    <span />
                    <span />
                  </button>
                </Col>
              </Row>
            </div>
            {/* Navigation */}
            <Nav navbar>{createLinks(routes)}</Nav>
            {/* Divider */}
            <hr className="my-3" />
            {/* Heading */}
            <h6 className="navbar-heading text-muted">Documentation</h6>
            {/* Navigation */}
            <Nav className="mb-md-3" navbar>
              <NavItem>
                <a
                  className="nav-link"
                  rel="noopener noreferrer" target="_blank"
                  href="https://intercom.help/gong-samesame/fr/"
                >
                  <i className="ni ni-support-16 text-default" />
                  Aide en ligne
                </a>
                {/*<NavLink href="https://intercom.help/gong-samesame/fr/">
                  <i className="ni ni-support-16" />
                  Aide en ligne
                </NavLink>*/}
              </NavItem>
            </Nav>
            {user.happyNeuronId && (
            <div>
              {/* Divider */}
              <hr className="my-3" />
              {/* Heading */}
              <h6 className="navbar-heading text-muted">Mini Formation</h6>
              <Nav className="mb-md-3" navbar>
                <NavItem

                >
                  <a
                      className="nav-link"
                      href="#"
                      onClick={() => showVideo(0)}
                  >
                    <i className="ni ni-button-play text-default" />
                    Présentation - 1 min
                  </a>
                </NavItem>
                <NavItem>
                  <a
                      className="nav-link"
                      href="#"
                      onClick={() => showVideo(1)}
                  >
                    <i className="ni ni-button-play text-default" />
                    Pourquoi une CAA ? - 2 min
                  </a>
                </NavItem>
                <NavItem>
                  <a
                      className="nav-link"
                      href="#"
                      onClick={() => showVideo(2)}
                  >
                    <i className="ni ni-button-play text-default" />
                    Un cas pratique - 4 min
                  </a>
                </NavItem>
                <NavItem>
                  <a
                      className="nav-link"
                      href="#"
                      onClick={() => showVideo(3)}
                  >
                    <i className="ni ni-button-play text-default" />
                    Principe de l'application - 8 min
                  </a>
                </NavItem>
                <NavItem>
                  <a
                      className="nav-link"
                      href="#"
                      onClick={() => showVideo(4)}
                  >
                    <i className="ni ni-button-play text-default" />
                    La 1ère séance - 7 min
                  </a>
                </NavItem>
                <NavItem>
                  <a
                      className="nav-link"
                      href="#"
                      onClick={() => showVideo(5)}
                  >
                    <i className="ni ni-button-play text-default" />
                    Conclusion - 5 min
                  </a>
                </NavItem>
              </Nav>
            </div>
            )}
            {!user.happyNeuronId && (
              <Nav className="mb-md-3" navbar>
                <NavItem >
                  <ButtonSignOut onClick={(e) => {
                    useSignOut().then(e => history.push("/login"))
                  }}>
                    <i className="ni ni-button-power" style={{color: "#D8636F"}} />
                    <span style={{verticalAlign: "middle", paddingTop: "3px"}}>Se déconnecter</span>
                  </ButtonSignOut>
                </NavItem>
              </Nav>
            )}
          </Collapse>
        </Container>
      </Navbar>
    );
}

Sidebar.defaultProps = {
  routes: [{}]
};

Sidebar.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...'>...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired
  })
};

export default Sidebar;
