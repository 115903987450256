import React from 'react'
import styled from '@emotion/styled'
import ClipLoader from "react-spinners/ClipLoader";

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`

export default({size}) => {

  return (
    <Container>
      <ClipLoader
        size={size}
        color={"#e4b33c"}
      />
    </Container>
  )
}
