/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Index from "views/index.js";
import Profile from "views/examples/Profile.js";
import ListUsersAdmin from "views/examples/ListUsersAdmin.js";
import BaseItems from "views/examples/BaseItems.js";
import ListPatients from "./views/examples/ListPatients";

var routes = [
  {
    path: "/index",
    name: "Application",
    icon: "ni ni-tv-2 text-blue",
    component: Index,
    layout: "/dashboard"
  },
  {
    path: "/user-profile",
    name: "Profile",
    icon: "ni ni-single-02 text-default",
    component: Profile,
    layout: "/dashboard"
  },
  {
    path: "/patients",
    name: "Mes Patients",
    icon: "ni ni-bullet-list-67 text-red",
    component: ListPatients,
    layout: "/dashboard"
  },
  {
    path: "/users",
    name: "Utilisateurs",
    icon: "ni ni-settings text-primary",
    component: ListUsersAdmin,
    layout: "/dashboard"
  },
  {
    path: "/base-items",
    name: "Collection Base Items",
    icon: "ni ni-app text-blue",
    component: BaseItems,
    layout: "/dashboard"
  },
];
export default routes;
