/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Navbar,
  Nav,
  Container,
  Media
} from "reactstrap";

const AdminNavbar = ({user, location}) => {
    return (
      <>
        <Navbar className="navbar-top navbar-dark navbar-admin" expand="md" id="navbar-main">
          <Container fluid>
            <Nav className="align-items-center d-none d-md-flex" navbar>
              <Media className="align-items-center">
                    <span className="">
                      <i style={{color: "white", fontSize: "25px"}} className="ni ni-circle-08"/>
                    </span>
                <div style={{marginTop: "-3px"}} className="ml-2 d-none d-lg-block">
                      <span style={{color: "white"}}>
                        {user.last_name} {user.first_name}
                      </span>
                </div>
              </Media>
            </Nav>
          </Container>
        </Navbar>
      </>
    );
}

export default AdminNavbar;
