import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import {
  getUser,
  getAllUsers,
  updateProfile,
  createUser,
  updateTherapist } from '../utils/API';

import {
    setUserAction,
    setLoadingAction,
    unsetLoadingAction,
    setErrorCreatePatient,
    unsetErrorCreatePatient,
    setErrorCreateTherapist,
    unsetErrorCreateTherapist,
    cleanSessionUser,
} from '../store/actions/userActions';

import {
  setAllPatients,
  addPatientsList,
  cleanSessionTherapist } from '../store/actions/therapistActions';
import { setAllUsers, cleanSessionAdmin } from '../store/actions/adminActions';
import { SignUp, signOut, isAuthenticated } from '../utils/auth';

const useAuth = () => {

    console.log('DANS LE HOOK');

    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const allUsers = useSelector(state => state.allUsers);
    const allPatients = useSelector(state => state.allPatients);

    const setUser = user => {
        dispatch(setLoadingAction());
        getUser(user)
            .then(res => {
                let user = res.data.getUser;
                let isAdmin = user.roles[0].name === 'admin';
                console.log('USER : ',user);
                console.log('admin : ',isAdmin);
                window.sendinblue.identify(user.email, { HAS_CONNECTED: true }, () => {
                  window.sendinblue.track('new_patient_connexion', {
                      HAS_CONNECTED: true,
                    });
                });
                isAuthenticated().then(data => {
                    user.idToken = data.signInUserSession.idToken.jwtToken;
                    user.accessToken = data.signInUserSession.accessToken.jwtToken;
                    console.log(data);
                    if (data.signInUserSession.accessToken.payload['cognito:groups'] && data.signInUserSession.accessToken.payload['cognito:groups'].find(item => item === 'admin')){
                      isAdmin = true;
                    }

                    console.log('admin : ',isAdmin);
                    dispatch(setUserAction({ ...user, isAdmin }));
                    if (isAdmin) {
                      getAllUsers()
                            .then(allUsers => {
                                dispatch(setAllUsers(allUsers.data.getAllUser));
                                getAllPatients(user.manage);
                              });

                    } else if (user.roles[0].name === 'speechTherapist')
                        getAllPatients(user.manage);
                  }).catch(err => {
                    console.log(err);
                  });
              }).catch(err => {
              console.log(err);
            });
          };

    const getAllPatients = async patients => {
        const listPatientsId = patients.filter(patient => patient.role === 'patient');
        const allPatients = [];
        for (let patient of listPatientsId) {
          allPatients.push(await getUser(patient.id));
        }

        Promise.all(allPatients).then(res => {
            let cleanList = [];
            res.forEach(item => {
              if (item.data.getUser) cleanList.push({ ...item.data.getUser });
            });
            dispatch(setAllPatients(cleanList));
            dispatch(unsetLoadingAction());
          });
      };

    const updateUser = (user, id) => {
        dispatch(setLoadingAction());
        updateProfile(user, id)
            .then((res) => {
              let user = res.data.putUser;
              dispatch(setUserAction(user));
            });
      };

    const createNewPatient = (newPatient, therapist) => {
        dispatch(setLoadingAction());
        SignUp(newPatient.email, newPatient.password)
            .then(res => {
                const password = newPatient.password;
                const theDate = moment();
                const theDateUnix = Date.now();
                const endTrial = theDate.add(60, 'days').format('x');
                newPatient.roles = [{
                    permissions: 'user',
                    name: 'patient'
                  }];
                newPatient.license = [{
                    name: 'patientTrialLicense',
                    duration: 60,
                    begin: Math.floor(theDateUnix / 1000),
                    end: Math.floor(endTrial / 1000)
                  }];
                newPatient.configs = [{
                    name: 'primary',
                    display_card_number: 3,
                    display_horizontal: true,
                    display_signature: true,
                    display_yes_no: true,
                    speech_on_each_card: true,
                    speech_speed: 0.5,
                    speech_voice_tonality: 1,
                    picture_style: 'rounded',
                  }];
                newPatient.has_signed_cgu = false;
                newPatient.manage = [{
                    id: therapist.id,
                    role: 'speechTherapist'
                  }];
                delete newPatient.password;
                delete newPatient.rePassword;
                createUser(newPatient, res.userSub)
                    .then(() => {
                        const allPatients = therapist.manage.length > 0 ?
                        [...therapist.manage, {
                            id: res.userSub,
                            role: 'patient'
                          }] : [{ id: res.userSub, role: 'patient' }];
                        updateTherapist(therapist.id, allPatients)
                            .then(() => {
                                fetch('https://1v099afibk.execute-api.eu-west-1.amazonaws.com/latest/new_patient', {
                                    method: 'POST',
                                    body: JSON.stringify({
                                        email: newPatient.email,
                                        NOM: newPatient.last_name,
                                        EMAIL: newPatient.email,
                                        PASSWORD: password,
                                        END_TRIAL: theDate.format('YYYY-MM-DD'),
                                        TRIALS_DAYS_COUNT: 60,
                                        HAS_CONNECTED: false,
                                        HAS_PAID: false,
                                    }),
                                })
                            })
                            .then(therapistUpdated => {
                                dispatch(addPatientsList(newPatient))
                                dispatch(unsetLoadingAction())
                            })
                            .catch(err => console.log(err))
                    })
                    .catch(err => {
                        console.log("ERROR CREATION2", err)
                        dispatch(setErrorCreatePatient(newPatient, err.code));
                    })
            })
            .catch(err => {
                console.log("ERROR CREATION", err)
                dispatch(setErrorCreatePatient(newPatient));
            })
    }

    const CleanErrorPatient = () => {
        dispatch(unsetErrorCreatePatient());
    }

    const CleanErrorTherapist = () => {
        dispatch(unsetErrorCreateTherapist());
    }

    const createNewTherapist = (therapist) => {
        dispatch(setLoadingAction());
        const password = `Gong69=${therapist.email.split('@')[0].toLowerCase()}`;
        SignUp(therapist.email, password)
            .then(res => {
                      therapist.roles = [{
                        permissions: 'user',
                        name: 'speechTherapist',
                      }];
                      therapist.license = [{
                        name: 'therapistLicense',
                        duration: 'lifetime',
                        begin: Math.floor(Date.now() / 1000),
                      }];
                      therapist.configs = [{
                        name: 'primary',
                        display_card_number: 3,
                        display_horizontal: true,
                        display_signature: true,
                        display_yes_no: true,
                        speech_on_each_card: true,
                        speech_speed: 0.5,
                        speech_voice_tonality: 1,
                        picture_style: 'rounded',
                      }];
                      therapist.manage = [];
                      therapist.has_signed_cgu = false;
                      createUser(therapist, res.userSub)
                        .then(() => {
                            getAllUsers()
                                .then(allUsers => dispatch(setAllUsers(allUsers.data.getAllUser)))
                                .then(() => {
                                    fetch('https://1v099afibk.execute-api.eu-west-1.amazonaws.com/latest/new_speech_therapist', {
                                        method: 'POST',
                                        body: JSON.stringify({
                                            email: therapist.email,
                                            name: therapist.last_name + " " + therapist.first_name,
                                            password: password,
                                          }),
                                        });
                                      })
                                .then(() => dispatch(unsetLoadingAction()));
                              })
                        .catch(err => {
                            dispatch(unsetLoadingAction());
                            dispatch(setErrorCreateTherapist(therapist, err.code));
                          });
                        })
                .catch(err => {
                    dispatch(unsetLoadingAction());
                    dispatch(setErrorCreateTherapist(therapist));
                })
    }

    const useSignOut = () => {
        return new Promise(resolve => {
            dispatch(setLoadingAction());
            signOut().then(() => {
                dispatch(cleanSessionUser())
                if (user.roles[0].name !== "patient") {
                    dispatch(cleanSessionTherapist())
                    if (user.isAdmin) {
                        dispatch(cleanSessionAdmin())
                        resolve()
                    }
                }
            })
        })
    }

    return {
        setUser,
        createNewPatient,
        createNewTherapist,
        user,
        allUsers,
        allPatients,
        getAllPatients,
        updateUser,
        CleanErrorPatient,
        CleanErrorTherapist,
        useSignOut
    }
};

export default useAuth
