/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useState} from "react";
import {Switch, Redirect} from "react-router-dom";
// reactstrap components
import {Container, Row, Col} from "reactstrap";
import styled from '@emotion/styled'
import {isLoggedIn} from "../utils/auth";
import Login from "../views/examples/Login"
import {ModalHNAcountNotAuthorized} from "../components/Modal";
import RecoverPassword from "../views/examples/PasswordRecovery";

const Header = styled.div`
  background: linear-gradient(40deg,#fbc939 0,#c38005 100%);
`;

const Auth = ({location, history}) => {

  const [showModal, setShowModal] = useState(false)
  const [showRecoverPassword, setShowRecoverPassword] = useState(false)

  const handleSuccess = () => {
    history.push("/dashboard/index")
  }

  const handleHNAccount = () => {
    setShowModal(true);
  }

  const handleDisplayRecoverPassword = () => {
    setShowRecoverPassword(true)
  }

  if (showRecoverPassword) return (
      <>
        <div className="main-content">
          <Header className="header py-7">
            <Container>
              <div className="header-body text-center mb-7">
                <Row className="justify-content-center">
                  <Col lg="5" md="6">
                    <h1 className="text-white">Mot de passe oublié ?</h1>
                    <p className="text-lead text-white">
                      Obtenez un code de vérification par email afin de définir un nouveau mot de passe
                    </p>
                  </Col>
                </Row>
              </div>
            </Container>
          </Header>
          {/* Page content */}
          <Container className="mt--8 pb-5">
            <Row className="justify-content-center">
              <Switch>
                <RecoverPassword authSuccess={handleSuccess} handleHNAccount={handleHNAccount} />
              </Switch>
            </Row>
          </Container>
          <ModalHNAcountNotAuthorized toggle={showModal} />
        </div>
      </>
  )

  return !isLoggedIn() ? (
    <>
      <div className="main-content">
        <Header className="header py-7">
          <Container>
            <div className="header-body text-center mb-7">
              <Row className="justify-content-center">
                <Col lg="5" md="6">
                  <h1 className="text-white">Bienvenue!</h1>
                  <p className="text-lead text-white">
                    Connectez-vous avec vos identifiants grâce au<br/> formulaire ci-dessous
                  </p>
                </Col>
              </Row>
            </div>
          </Container>
        </Header>
        {/* Page content */}
        <Container className="mt--8 pb-5">
          <Row className="justify-content-center">
            <Switch>
              <Login toggleRecoverPassword={handleDisplayRecoverPassword} authSuccess={handleSuccess} handleHNAccount={handleHNAccount} happyNeuronLogin={false}/>
            </Switch>
          </Row>
        </Container>
        <ModalHNAcountNotAuthorized toggle={showModal} />
      </div>
    </>
  ) : <Redirect from="/login" to="/dashboard/index" />;

}

export default Auth;
